import React from "react";
import { NavLink, Routes, Route } from 'react-router-dom';
import Resume from './Resume';
import Projects from './Projects';
import { useTranslation } from 'react-i18next';

const Main = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className='grid-flow-col m-5'>
                <NavLink to='/' className="m-2 border px-2.5 py-px" >{t('resume')}</NavLink>
                <NavLink to='/projects' className="m-2 border px-2.5 py-px rounded ">{t('projects')}</NavLink>
            </div>
          <Routes>
            <Route path='/' element={<Resume />} />
            <Route path='/projects' element={<Projects />} />
          </Routes>
        </>
    )
};

export default Main;