import React from "react";


const Project = ({ name, tools, image, git, id }) => {

    const handleCLick = (id) => {
        console.log(id);
        window.open(git, ' ');
    }

    return (
        <div 
            className='group relative flex flex-col text-center m-5 border-4 border-slate-200 rounded-xl w-auto h-auto
            hover:cursor-pointer hover:border-sky-300'
            id={id}
            onClick={()=>handleCLick(id)}
        >
            <img className='object-contain rounded-t-lg group-hover:visible' src={image} alt='project'/>
                <p className='p-px text-xl bg-slate-200 text-slate-900 group-hover:bg-sky-300 group-hover:text-slate-100 '>{name}</p>
                <p className='text-sm bg-slate-200 rounded-b-lg text-slate-200 group-hover:bg-sky-300 group-hover:text-slate-100'>{tools}</p>
        </div>

    )
};

export default Project;