import React, { useState } from 'react';
import './App.css';
import Main from './components/Main';
import Sidebar from './components/Sidebar';

function App() {
  const [ lang, setLang ] = useState('en');

  const langChange = e => {
    setLang(e.target.value);
    let loc = 'https://gaukharit.com'
    window.location.replace(loc + '?lng=' + e.target.value)
    console.log(lang)
  }

  return (
    <div className="w-screen h-screen grid grid-cols-12 text-slate-900">
      <div className="col-span-12 lg:col-span-3 m-8 text-slate-800 bg-slate-400 border-2 rounded-lg justify-center text-center min-w-max">
        <Sidebar langChange={langChange} />
      </div>
      <div className="col-span-12 lg:col-span-9 m-8 bg-slate-400 border-2 rounded-lg">
        <Main />
      </div>
    </div>
  )
}

export default App;
