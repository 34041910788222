import React, { useEffect, useState } from 'react';

const ProgressBar = ({ progress, skill }) => {

    const [ bar, setBar ] = useState(100);

    useEffect(() => {
        setTimeout(()=> setBar(progress), 1000);
    }, [])

    return (
        <div className='h-7 bg-slate-300 m-5 rounded-3xl'>
            <div style={{ width: `${bar}%`, transition: '1s ease-in-out'}} className='h-full bg-gradient-to-r from-sky-500 to-violet-700 rounded-3xl'>
                <span className='m-5 text-slate-200'>{skill}</span>
            </div>
        </div>
    )
}

export default ProgressBar;