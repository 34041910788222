import React from 'react';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { GoLocation } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

const languages = [
  { value: 'en', text: 'EN' },
  { value: 'ru', text: 'RU' },
  { value: 'kk', text: 'KZ' }
]

const Sidebar = ({langChange}) => {
  const { t } = useTranslation();

  return (
    <>
        <div className='my-2 text-sm'>
            {
              languages.map((item) => (<button 
                                        className='m-1.5 border p-0.5 rounded bg-slate-500 text-white text-xs'
                                        key={item.value}
                                        value={item.value}
                                        onClick={langChange} >
                {item.text}
                </button>))
            }
        </div>
        <Link to='/'>
            <img src='avatar.jpg' alt="avatar" className=" w-40 h-50 m-auto rounded-full border-2"/>
        </Link>
        <h2 className='text-xl mx-2 my-5'>{t('name')}</h2>
        <h3 className='text-lg font-bold my-2'>{t('dev')}</h3>
        <div className='my-5'>
            <span className='mx-px'><SocialIcon url="https://www.linkedin.com/in/gaukhar-jekpeldinova" bgColor='#6fcaee' style={{ width:'40px', height: '40px'}}/> </span>
            <span className='mx-px'><SocialIcon url="https://gitlab.com/grakhimbayeva" bgColor='#6fcaee' style={{ width:'40px', height: '40px'}}/> </span>
            {/* <span className='mx-px'><SocialIcon url="https://leetcode.com/grakhimbayeva" bgColor='#6fcaee' style={{ width:'40px', height: '40px'}}/> </span> */}
        </div>
        <a href='GaukharJekpeldinova.pdf' download>
          <button className='border p-0.5 rounded text-sm text-white bg-slate-500 px-2'>{t('cv')}</button>
        </a>
        <div className='my-5'>
          <span className='mx-1'><SocialIcon url="mailto: jekpeldinova@gmail.com" bgColor='#6fcaee' style={{ width:'40px', height: '40px'}} /></span>
          <span className='mx-1'><SocialIcon url='https://wa.me/77012115397' bgColor='#6fcaee' style={{ width:'40px', height: '40px'}} network='whatsapp'/></span>
          <span className='mx-1'><SocialIcon url="https://t.me/jekpeldinova" bgColor='#6fcaee' style={{ width:'40px', height: '40px'}}/> </span>
        </div>
        <div className='mt-10 mb-4 text-xs'> 
          <GoLocation className='m-auto'/> 
          <span>{t('location')}</span>
        </div>
    </>
  )
}

export default Sidebar;


