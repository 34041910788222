import React from 'react';
import ProgressBar from './ProgressBar';
import { skills } from '../data';
import { useTranslation } from 'react-i18next';


const Resume = () => {
  const { t } = useTranslation();
  
  return (
    <div className='m-5'>
      <p className='m-10 text-sm text-justify border py-3 px-5 rounded-xl'>
        {t('about')}
      </p>
      <div className='m-3'>
        <h2 className='text-xl font-bold text-center my-10'>{t('skills')}</h2>
          {
            skills.map((skill, index) => (
              <ProgressBar 
                skill={skill.name} 
                progress={skill.progress} 
                key={index}
              />
            ))
          }
      </div>
    </div>
  )
}

export default Resume;