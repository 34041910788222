export const projectList = [
  
    {

        name: 'Catch the Pet Game',
        tools: 'Javascript, HTML, CSS',
        image: 'pets.png',
        git: 'https://gitlab.com/grakhimbayeva/pets-game'
    },

    {
        name: 'Split Animation',
        tools: 'Javascript, HTML, CSS',
        image: 'split.png',
        git: 'https://gitlab.com/grakhimbayeva/split_page'
    },

    {
        name: 'Covid-19 Tracker in KZ',
        tools: 'React JS, REST API, TailwindCSS, HTML',
        image: 'covid.png',
        git: 'https://gitlab.com/grakhimbayeva/covid-19-kazakhstan'
    },

    {
        name: 'Star Wars Persons',
        tools: 'React, Redux Toolkit, GraphQL, SASS, HTML',
        image: 'inprogress.png',
        git: 'https://gitlab.com/grakhimbayeva/star-wars-graphql'
    }
]


export const skills = [
    { name: 'React JS', progress: 80 },
    { name: 'Javascript/Typescript', progress: 70 },
    { name: 'HTML', progress: 90 },
    { name: 'CSS', progress: 80 },
    { name: 'Python(Django)', progress: 40 },
    { name: 'REST API', progress: 70 },
    { name: 'Bootstrap', progress: 60 },
    { name: 'Tailwind', progress: 60 },
  ]