import React from 'react';
import Project from './Project';
import { projectList } from '../data';

const Projects = () => {

  return (
    <div className='m-2 grid grid-cols-2 xl:grid-cols-3'>
        {
            projectList.map((project, index) => (
                <Project
                    name={project.name}
                    tools={project.tools}
                    image={project.image}
                    git={project.git}
                    key={index}
                    id={index}
                />
            ))
        }
    </div>
  )
}

export default Projects;